<template>
  <div>
    <div>{{ topic }}</div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="6">
          <label>ชื่อผู้รับ</label><span class="red--text">*</span>
          <v-text-field
            outlined
            :rules="rules"
            name="name"
            v-model="model.fullName"
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label>โทรศัพท์</label><span class="red--text">*</span>
          <v-text-field
            outlined
            :rules="rules"
            v-model="model.phone"
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <label>ที่อยู่</label><span class="red--text">*</span>
          <v-text-field
            name="address"
            outlined
            :rules="rules"
            v-model="model.address"
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label>จังหวัด</label><span class="red--text">*</span>
          <v-autocomplete
            outlined
            dense
            @change="onProvinceChange"
            :rules="rules"
            :items="provinces"
            item-text="name"
            item-value="code"
            v-model="model.province"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <label>อำเภอ/เขต</label><span class="red--text">*</span>
          <v-autocomplete
            outlined
            dense
            @change="onDistrictChange"
            :rules="rules"
            :items="districts"
            item-text="name"
            item-value="district_id"
            v-model="model.district"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <label>ตำบล/แขวง</label><span class="red--text">*</span>
          <v-autocomplete
            outlined
            dense
            :rules="rules"
            :items="subDistricts"
            item-text="name"
            item-value="subdistrict_id"
            v-model="model.sub_district"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <label>รหัสไปรษณีย์</label><span class="red--text">*</span>
          <v-text-field
            outlined
            :rules="zipcodeRules"
            v-model="model.post_code"
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label>อีเมล์</label><span class="red--text">*</span>
          <v-text-field
            outlined
            :rules="emailRules"
            name="email"
            v-model="model.email"
            dense
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: ['topic'],
  data() {
    return {
      rules: [(v) => !!v || 'This Field is required'],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      zipcodeRules: [
        (v) => !!v || 'This Field is required',
        (v) => (v && v.length === 5) || 'This Field must be 13 characters'
      ],
      valid: false,
      model: {
        fullName: '',
        phone: '',
        address: '',
        province: '',
        district: '',
        sub_district: '',
        post_code: '',
        email: '',
        type: 'thai'
      }
    }
  },
  computed: {
    ...mapState({
      provinces: (state) => state.MasterData.provinces,
      districts: (state) => state.MasterData.districts,
      subDistricts: (state) => state.MasterData.subDistricts
    })
  },
  methods: {
    onProvinceChange(event) {
      this.$store.dispatch('MasterData/onGetDistrict', event)
    },
    onDistrictChange(event) {
      this.$store.dispatch('MasterData/onGetSubDistrict', event)
    },
    validate() {
      if (this.$refs.form.validate()) {
        return this.model
      } else {
        return false
      }
    }
  }
}
</script>

<style>
</style>