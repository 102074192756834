<template>
  <div>
    <div>{{topic}}</div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="6">
          <label>성함 (받으실 분)</label><span class="red--text">*</span>
          <v-text-field
            outlined
            :rules="rules"
            name="name"
            v-model="model.fullName"
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label>연락처</label><span class="red--text">*</span>
          <v-text-field
            outlined
            :rules="rules"
            v-model="model.phone"
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label>시 / 도 </label><span class="red--text">*</span>
          <v-autocomplete
            outlined
            dense
            @change="onProvinceChange"
            :rules="rules"
            :items="provincesKR"
            item-text="name"
            item-value="id"
            v-model="model.province"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <label>시 / 군 / 구 </label><span class="red--text">*</span>
          <v-autocomplete
            outlined
            dense
            :rules="rules"
            :items="districtsKR"
            item-text="name"
            item-value="id"
            v-model="model.district"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <label>상세 주소 ( 길 / 번지 / 아파트 ) </label
          ><span class="red--text">*</span>
          <v-text-field
            outlined
            name="address"
            :rules="rules"
            v-model="model.address"
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label>고유통관번호 (P로 시작 13자리)</label
          ><span class="red--text">*</span>
          <v-text-field
            outlined
            :rules="taxNumberRules"
            v-model="model.taxNumber"
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label>우편번호</label><span class="red--text">*</span>
          <v-text-field
            outlined
            :rules="rules"
            v-model="model.post_code"
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label>이메일 주소</label><span class="red--text">*</span>
          <v-text-field
            outlined
            name="email"
            :rules="emailRules"
            v-model="model.email"
            dense
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props:['topic'],
  data() {
    return {
      rules: [(v) => !!v || 'This Field is required'],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      taxNumberRules: [
        (v) => !!v || 'This Field is required',
        v => (v && v.length === 13) || 'This Field must be 13 characters',
      ],
      valid: false,
      model: {
        fullName: '',
        phone: '',
        address: '',
        province: '',
        district: '',
        taxNumber: '',
        post_code: '',
        email: '',
        type:'korea'
      }
    }
  },
  computed: {
    ...mapState({
      provincesKR: (state) => state.MasterData.provincesKR,
      districtsKR: (state) => state.MasterData.districtsKR
    })
  },
  methods: {
    onProvinceChange(event) {
      this.$store.dispatch('MasterData/onGetDistrictKR', event)
    },
    validate() {
      if (this.$refs.form.validate()) {
        return this.model
      } else {
        return false
      }
    }
  }
}
</script>

<style>
</style>