<template>
  <div>
    <div
      class="layout-border pa-5 mb-5"
      v-if="delivery && delivery.address_type === 'thai'"
    >
      <div class="txt-bigger mb-5">{{$t('address.yourAddress')}} : THAILAND</div>
      <div class="mb-2 txt-detail">
        {{ delivery.fullName_th }}
      </div>
      <div class="mb-2 txt-detail">
        {{ delivery.address_th }}
      </div>
      <div class="mb-2 txt-detail">
        {{ delivery.province_th }}
        {{ delivery.district_th }}
        {{ delivery.sub_district_th }}
        {{ delivery.post_code_th }}
      </div>
    </div>
    <div
      class="layout-border pa-5 mb-5"
      v-if="delivery && delivery.address_type === 'korea'"
    >
      <div class="txt-bigger mb-5">{{$t('address.yourAddress')}} : KOREA</div>
      <div class="mb-2 txt-detail">
        {{ delivery.fullName_kr }}
      </div>
      <div class="mb-2 txt-detail">
        {{ delivery.address_kr }}
      </div>
      <div class="mb-2 txt-detail">
        {{ delivery.province_kr }}
        {{ delivery.district_kr }}
        {{ delivery.post_code_kr }}
      </div>
    </div>
    <div
      class="layout-border pa-5 mb-5"
      v-if="delivery && delivery.address_type === 'other'"
    >
      <div class="txt-bigger mb-5">
        {{$t('address.yourAddress')}} : {{ delivery.countryName.toUpperCase() }}
      </div>
      <div class="mb-2 txt-detail">
        {{ delivery.fullName }}
      </div>
      <div class="mb-2 txt-detail">
        {{ delivery.addressName }}
      </div>
      <div class="mb-2 txt-detail">
        {{ delivery.provinceName }}
        {{ delivery.cityName }}
        {{ delivery.zipCode }}
      </div>
    </div>

    <div
      class="layout-border pa-5 mb-5"
      v-if="delivery && delivery.address_type === 'shop'"
    >
      <div class="txt-bigger mb-5">
        {{$t('address.selfPickup')}}
<!--        Without Shipping (I will go to pick up to Hello Custom by myself)-->
<!--        배송선택안함  (업체전용)  카카오톡 thisdays2-->
      </div>
      <div class="mb-2 txt-detail">
        {{ delivery.fullName }}
      </div>
      <div class="mb-2 txt-detail">
        {{ delivery.addressName }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['delivery'],
  created() {
    this.$emit('clickBanner')
  }
}
</script>

<style>
</style>
