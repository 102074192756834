<template>
  <div>
    <Loading :loading="isLoading"></Loading>
    <div v-if="!isAccountSetting" class="txt-head mb-5">
      <v-icon> mdi-map-marker-outline</v-icon> Address
    </div>
    <div v-if="!firstStep">
      <div v-if="Object.keys(listAddress).length > 1">
        <div class="txt-bigger mb-5">{{$t('address.chooseAddress')}} :</div>
        <CheckBox
          class="mb-5"
          :isCheck="useBilling"
          @onChange="ChangeUseDelivery"
          :label="$t('address.useBilling')"
        ></CheckBox>
        <div class="mb-5">
          <SelectBox
            :items="selectAddress"
            item_text="text"
            item_value="value"
            :value="selectAddress[0]"
            @onChange="changeAddress"
          ></SelectBox>
        </div>
      </div>
      <AddressPanel :delivery="selectedAddressDelivery"></AddressPanel>

      <AddressPanel
        v-if="!useBilling"
        :delivery="selectedAddressBilling"
      ></AddressPanel>
      <div class="txt-detail mb-5">
<!--        If you would like to add a comment about your order, please write it in-->
<!--        the field below.-->
        {{$t('address.comment')}}
      </div>
      <v-textarea outlined v-model="txtComment"></v-textarea>
      <v-btn class="cust-btn-color mb-5" @click="firstStep = true"
        >{{$t('address.addAddress')}}</v-btn
      >

      <v-row justify="end">
        <v-col md="3" cols="12">
          <v-btn
            class="cust-border-btn-color"
            block
            v-if="!isAccountSetting"
            @click="$router.push({ name: 'Home' })"
            ><v-icon small class="mr-2">chevron_left</v-icon>{{$t('checkoutStep.continueShopping')}}</v-btn
          >
        </v-col>
        <v-col md="3" cols="12">
          <v-btn
            v-if="!isAccountSetting"
            block
            class="cust-btn-color"
            @click="eventNext"
            >{{$t('checkoutStep.checkout')}}</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <div
        v-if="isShipping !== null"
        class="pointer"
        @click="isShipping = null; addressType = null"
      >
        <i class="bi bi-arrow-left"></i> <b>BACK</b>
      </div>
      <v-row v-if="isShipping == null && firstStep">
        <v-col v-if="!noAddress" cols="12" class="pointer" @click="firstStep = false">
          <i class="bi bi-arrow-left"></i> <b>BACK</b>
        </v-col>
        <v-col cols="12">
          <v-btn @click="selectNoShipping()" block elevation="2" x-large>
            {{ $t('selectAddress.shipping') }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn @click="isShipping = true" block elevation="2" x-large>
            {{ $t('selectAddress.notShipping') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-expansion-panels v-if="isShipping" style="display: contents">
        <v-radio-group v-model="addressType">
          <v-expansion-panel @click="addressType = 'thai'" class="mb-3">
            <v-expansion-panel-header
              style="background: #e5e5e5"
              expand-icon=""
            >
              <v-row class="text-center" align="center">
                <v-col cols="1" md="2">
                  <v-radio value="thai"></v-radio>
                </v-col>
                <v-col cols="8">
                  <h2 class="text-center">
                    {{ $t('selectAddress.deliveryInThailand') }}
                  </h2>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-expansion-panel>

          <v-expansion-panel @click="addressType = 'korea'" class="mb-3">
            <v-expansion-panel-header
              style="background: #e5e5e5"
              expand-icon=""
            >
              <v-row class="text-center" align="center">
                <v-col cols="1" md="2">
                  <v-radio value="korea"></v-radio>
                </v-col>
                <v-col cols="8">
                  <h2>
                    {{ $t('selectAddress.deliverToSouthKorea') }}
                  </h2>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-expansion-panel>

          <v-expansion-panel @click="addressType = 'other'">
            <v-expansion-panel-header
              style="background: #e5e5e5"
              expand-icon=""
            >
              <v-row class="text-center" align="center">
                <v-col cols="1" md="2">
                  <v-radio value="other"></v-radio>
                </v-col>
                <v-col cols="8">
                  <h2>
                    {{ $t('selectAddress.shippingToOtherCountry') }}
                  </h2>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-radio-group>
      </v-expansion-panels>
      <div v-if="addressType !== null" class="layout-border py-3 px-5">
        <AddressKR
          topic="- Delivery address"
          ref="addressInfo"
          v-if="addressType === 'korea'"
        ></AddressKR>
        <div v-if="addressType === 'korea' && !useBilling" class="px-3 my-5">
          <hr style="border-top: 1px solid black" />
        </div>
        <AddressKR
          topic="- Billing address"
          ref="addressInfoBilling"
          v-if="addressType === 'korea' && !useBilling"
        ></AddressKR>

        <AddressTH
          topic="- Delivery address"
          ref="addressInfo"
          v-if="addressType === 'thai'"
        ></AddressTH>
        <div v-if="addressType === 'thai' && !useBilling" class="px-3 my-5">
          <hr style="border-top: 1px solid black" />
        </div>
        <AddressTH
          topic="- Billing address"
          ref="addressInfoBilling"
          v-if="addressType === 'thai' && !useBilling"
        ></AddressTH>

        <AddressOther
          topic="- Delivery address"
          ref="addressInfo"
          v-if="addressType === 'other'"
        ></AddressOther>
        <div v-if="addressType === 'other' && !useBilling" class="px-3 my-5">
          <hr style="border-top: 1px solid black" />
        </div>
        <AddressOther
          topic="- Billing address"
          ref="addressInfoBilling"
          v-if="addressType === 'other' && !useBilling"
        ></AddressOther>
      </div>
      <div v-if="addressType !== null">
        <CheckBox
          class="mb-5"
          :is-check="useBilling"
          @onChange="useBilling = $event"
          :label="$t('address.useBilling')"
        ></CheckBox>
        <div class="mb-3 d-inline-flex">
          <v-btn @click="save" class="mr-3 cust-btn-color" width="100px"
            >Save</v-btn
          >
          <v-btn class="cust-border-btn-color" width="100px">{{$t('general.cancel')}}</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '../Loading.vue'
import AddressKR from '../Address/AddressKR.vue'
import AddressPanel from '../Address/Panel/AddressPanel.vue'
import AddressTH from '../Address/AddressTH.vue'
import AddressOther from '../Address/AddressOther.vue'
import SelectBox from '../input/SelectBox.vue'
import CheckBox from '../input/CheckBox.vue'
import ServiceAPI from '../../services'
import { mapState } from 'vuex'
export default {
  components: {
    AddressPanel,
    SelectBox,
    CheckBox,
    Loading,
    AddressKR,
    AddressTH,
    AddressOther
  },
  props: {
    isAccountSetting: {
      default: false
    }
  },
  computed: {
    ...mapState({
      accessToken: (state) => state.Auth.userDetail.access_token,
      is_address: (state) => state.Auth.userDetail.is_address
    })
  },
  async created() {
    this.isLoading = true
    this.$store.dispatch('Address/onGetCountry')
    this.$store.dispatch('MasterData/onGetProvince')
    this.$store.dispatch('MasterData/onGetProvinceKR')
    if (this.is_address) {
      await this.fetchAPIAddress()
      this.isLoading = false
    } else {
      this.firstStep = true
      this.isShipping = null
      this.isLoading = false
    }
  },
  data() {
    return {
      noAddress: false,
      firstStep: true,
      useBilling: true,
      addressType: null,
      isShipping: null,
      isLoading: false,
      txtComment: null,
      useAddressID: null,
      listAddress: [],
      selectAddress: [],
      selectedAddressDelivery: [],
      selectedAddressBilling: [],
      form: {
        access_token: null,
        use_billing: false,
        type: '',
        billing: {
          fullName: '',
          phone: '',
          address: '',
          province: '',
          district: '',
          sub_district: '',
          taxNumber: '',
          post_code: '',
          email: '',
          type: ''
        },
        address: {
          fullName: '',
          phone: '',
          address: '',
          province: '',
          district: '',
          sub_district: '',
          taxNumber: '',
          post_code: '',
          email: '',
          type: ''
        }
      }
    }
  },
  methods: {
    async selectNoShipping() {
      this.isLoading = true
      this.form.access_token = this.accessToken
      this.form.use_billing = true
      this.form.type = 'shop'
      this.form.address = null
      this.form.billing = null
      const res = await ServiceAPI.address.saveNewAddress(this.form)
      if (res.status == 'success') {
        this.$store.dispatch('Auth/saveAddress', true).then(() => {
          this.fetchAPIAddress()
          this.firstStep = false
          this.isLoading = false
          this.eventNext();
        })
      }
    },
    eventNext() {
      this.$emit('onNext', {
        useAddressID: this.useAddressID,
        step: 3,
        txtComment: this.txtComment
      })
    },
    ChangeUseDelivery() {
      this.useBilling = !this.useBilling
      this.isLoading = true
      if (this.useBilling) {
        this.selectedAddressBilling = this.selectedAddressDelivery
        this.isLoading = false
      } else {
        this.getDetailAddress(this.useAddressID)
      }
    },
    async changeAddress(addressID) {
      this.getDetailAddress(addressID)
      this.$store.dispatch('Address/useAddressID', addressID)
      this.isLoading = true
    },
    async getDetailAddress(addressID) {
      let res = await ServiceAPI.address.getDetailbyID({
        addressID: addressID,
        access_token: this.accessToken
      })
      this.useAddressID = addressID
      if (res.status == 'success') {
        this.selectedAddressDelivery = res.data.delivery
        this.selectedAddressBilling = res.data.billing
        if (res.data.useBilling) {
          this.use_billing = true
        }
        this.isLoading = false
      }
    },
    objectAddress(model) {
      switch (model.address_type) {
        case 'thai':
          return {
            text: 'THAILAND - ' + model.address_th,
            value: model.id
          }
        case 'korea': {
          return {
            text: 'KOREA - ' + model.address_kr,
            value: model.id
          }
        }
        case 'other': {
          return {
            text: `${model.countryName.toUpperCase()} - ` + model.addressName,
            value: model.id
          }
        }
        case 'shop': {
          return {
            text: `${model.fullName} - ` + model.addressName,
            value: model.id
          }
        }
      }
    },
    async fetchAPIAddress() {
      const res = await ServiceAPI.address.getAddress({
        access_token: this.accessToken
      })
      if (res.status == 'success') {
        if (res.data.length == 0) {
          this.firstStep = true
          this.noAddress = true
        } else {
          this.noAddress = false
          this.firstStep = false
          this.listAddress = res.data
          this.selectAddress = []
          Object.values(this.listAddress).map((d) => {
            if (typeof d != 'number') {
              this.selectAddress.push(this.objectAddress(d.delivery))
            } else {
              this.selectAddress.push(d)
            }
          })
          this.$store.dispatch('Address/onSelectAddress', this.selectAddress)
          this.$store.dispatch(
            'Address/useAddressID',
            this.listAddress[0]['delivery'].id
          )
          this.useAddressID = this.listAddress[0]['delivery'].id
          this.selectedAddressDelivery = this.listAddress[0]['delivery']
          this.selectedAddressBilling = this.listAddress[0]['billing']
          if (this.listAddress[0]['delivery'].useBilling) {
            this.use_billing = true
          }
        }
      }
    },
    async save() {
      const model = this.validateAllForm()
      if (model.validate) {
        this.isLoading = true
        this.form.access_token = this.accessToken
        this.form.use_billing = this.useBilling
        this.form.type = model.type
        this.form.address = model.address
        this.form.billing = model.billing
        const res = await ServiceAPI.address.saveNewAddress(this.form)
        if (res.status == 'success') {
          this.$store.dispatch('Auth/saveAddress', true).then(() => {
            this.fetchAPIAddress()
            this.firstStep = false
            this.isLoading = false
          })
        }
      }
    },
    validateAllForm() {
      const address = this.$refs.addressInfo.validate()
      const addressValid = this.$refs.addressInfo.valid
      if (!this.useBilling) {
        const billing = this.$refs.addressInfoBilling.validate()
        const billingValid = this.$refs.addressInfoBilling.valid
        return {
          validate: address && addressValid && billing && billingValid,
          type: address.type,
          billing: billing,
          address: address
        }
      } else {
        return {
          validate: address && addressValid,
          type: address.type,
          billing: null,
          address: address
        }
      }
    }
  }
}
</script>

<style>
</style>
