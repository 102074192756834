<template>
  <div>
    <div>{{ topic }}</div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="6">
          <label>Full Name</label><span class="red--text">*</span>
          <v-text-field
            :rules="rules"
            name="name"
            v-model="model.fullName"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label>Contact Number</label><span class="red--text">*</span>
          <v-text-field
            outlined
            :rules="rules"
            v-model="model.phone"
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <label>Address</label><span class="red--text">*</span>
          <v-text-field
            outlined
            :rules="rules"
            v-model="model.address"
            name="address"
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label>Country</label><span class="red--text">*</span>
          <v-autocomplete
            outlined
            dense
            :items="countries"
            v-model="model.country"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <label>Province</label><span class="red--text">*</span>
          <v-text-field
            outlined
            :rules="rules"
            v-model="model.province"
            name="province"
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label>City</label><span class="red--text">*</span>
          <v-text-field
            outlined
            :rules="rules"
            v-model="model.city"
            name="city"
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label>Zip / Post code</label><span class="red--text">*</span>
          <v-text-field
            outlined
            :rules="zipcodeRules"
            v-model="model.post_code"
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label>Email</label><span class="red--text">*</span>
          <v-text-field
            outlined
            :rules="rules"
            name="email"
            v-model="model.email"
            dense
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: ['topic'],
  data() {
    return {
      rules: [(v) => !!v || 'This Field is required'],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      zipcodeRules: [
        (v) => !!v || 'This Field is required',
        (v) => (v && v.length === 5) || 'This Field must be 13 characters'
      ],
      valid: false,
      model: {
        fullName: '',
        phone: '',
        address: '',
        province: '',
        city: '',
        country: '',
        post_code: '',
        email: '',
        type: 'other'
      }
    }
  },
  computed: {
    ...mapState({
      countries: (state) => state.Address.countryList
    })
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        return this.model
      } else {
        return false
      }
    }
  }
}
</script>

<style>
</style>